.container {
    padding: 30px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .attractiveBox {
    padding: 25px !important;
    border-radius: 15px !important;
    background: rgba(0, 0, 0, 0.8); /* Slightly transparent black */
    box-shadow: 0 0 20px rgba(0, 255, 204, 0.7); /* Glowing shadow effect */
    color: #ffffff !important;
    backdrop-filter: blur(5px);
    max-width: 800px;
    width: 100%;
  }
  
  .heading {
    color: #ffffff !important;
    font-weight: bold !important;
    margin-bottom: 20px !important;
    text-shadow: 0 0 10px #00ffcc; /* Glow effect */
  }
  
  .subheading {
    color: #b0bec5 !important;
    margin-bottom: 20px !important;
  }
  
  .formGrid {
    margin-top: 20px !important;
  }
  
  .inputField {
    background-color: #222 !important;
    color: #ffffff !important;
    border-radius: 5px !important;
    box-shadow: 0 0 10px rgba(0, 255, 204, 0.3); /* Subtle glow for inputs */
  }
  
  .inputField .MuiInputBase-input {
    color: #ffffff !important;
  }
  
  .errorText {
    color: #f44336 !important;
    font-weight: bold !important;
  }
  
  .submitButton {
    background-color: #00ffcc !important;
    color: #000 !important;
    transition: background-color 0.3s !important;
    box-shadow: 0 0 15px rgba(0, 255, 204, 0.6); /* Button glow */
    font-weight: bold !important;
  }
  
  .submitButton:hover {
    background-color: #00bfa5 !important;
    box-shadow: 0 0 20px rgba(0, 255, 204, 0.8); /* Intense glow on hover */
  }
  
  @media (max-width:800px){
    .container {
      padding: 5px !important;
      width: 100%!important;
    }
  }