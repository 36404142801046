:root {
  --animation-speed: 40s;
}

.slider {
  background: var(--slider-color);
  box-shadow: 0 10px 20px -5px var(--shadow-color);
  height: 100px;
  margin: 5px;
  overflow: hidden;
  position: relative;
  width: 80%;
}

.slider::before,
.slider::after {
  content: "";
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
  background: linear-gradient(to right, var(--gradient-start) 0%, var(--gradient-end) 100%);
}

.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.slider::before {
  left: 0;
  top: 0;
}

.slideTrack {
  animation: scroll var(--animation-speed) linear infinite;
  display: flex;
  width: calc(250px * 14);
  gap: 1rem!important;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}

.slide {
  height: 100px;
  width: 250px;
  background: var(--background-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.slide:hover {
  transform: scale(1.05);
  box-shadow: 0 5px 15px var(--shadow-color);
}

.slide img {
  display: block;
  border-radius: 5px;
  height: auto;
  max-width: 100%;
}

@media (max-width:800px){
  .slider {
    width: 100%;
  }
}