/* Main Accordion Container */
.dark-accordion {
  border-radius: 6px!important;
  margin-bottom: 15px!important;
  color: #e0e0e0!important;
}

/* Individual Accordion Items */
.dark-accordion-item {
  border: 1px solid #333!important;
  margin-bottom: 10px!important;
  background-color: #1a1a1a!important;
  border-radius: 6px!important;
}

/* Accordion Header (Question) Styles */
.accordion-button {
  background-color: #333 !important;
  color: #ffffff !important;
  border: none !important;
  border-radius: 6px !important;
  transition: background-color 0.3s ease-in-out!important;
}

/* Ensure Accordion Header Stays Dark When Expanded */
.accordion-button:not(.collapsed) {
  background-color: #444 !important;
  color: #ffffff !important;
}

/* Accordion Header Hover Effect */
.accordion-button:hover {
  background-color: #444 !important;
  color: #ffffff !important;
}

/* Accordion Body Content */
.dark-accordion-content {
  background-color: #2a2a2a!important;
  padding: 15px!important;
  color: #cccccc!important;
  border-top: 1px solid #444!important;
  border-radius: 0 0 6px 6px!important;
}

/* Remove default Bootstrap focus outline */
.accordion-button:focus {
  box-shadow: none !important;
}

/* Override Bootstrap focus styles */
.accordion-button:focus-visible {
  outline: none !important;
  box-shadow: none !important;
}
