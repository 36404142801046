.sliderContainer {
  padding: 40px 20px;
  border-radius: 10px;
  background-color: #1b1b1b; /* Dark background */
  text-align: center;
  color: white;
  width: 100%;
}

.heading {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #ffffff;
}

.sliderItem {
  cursor: pointer;
  padding: 10px;
  text-align: center;
  transition: transform 0.3s ease;
}

.sliderItem:hover {
  transform: scale(1.05); /* Zoom effect on hover */
}

.sliderImage {
  width: 100%;
  height: 150px; /* Adjust for consistent height */
  object-fit: contain;
  border-radius: 8px;
}

.sliderHeading {
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
}

/* Custom dots for the carousel */
.customDotList {
  position: relative;
  bottom: -20px;
}

.carousel-container {
  padding-bottom: 40px; /* Space for dots */
}

/* Responsive styling */
@media (max-width: 768px) {
  .sliderImage {
    height: 150px;
  }
  .heading {
    font-size: 24px;
  }
}

@media (max-width: 480px) {
  .sliderImage {
    height: 120px;
  }
  .heading {
    font-size: 20px;
  }
}
