.main{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 3rem 0rem;
    text-align: center;
}
.innerbox{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr ;
    gap: 1rem;
    margin-top: 2rem;
    padding: 3rem;
    
}
.img2{
    height: 100%;
    width: 100%;
    border-radius: 15px 15px  0px 0px;
    transition: transform 0.4s ease-in-out;
}
.card{
    height: 25rem;
    width: 22rem;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-top: 2rem 0rem 3rem 0rem;
    gap: .5rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    background-color:  rgb(240, 240, 241);
    transition: transform 0.3s ease-in-out;
    border-radius: 15px;
    overflow: hidden;  
}
.card:hover{
    transform: scale(1.01);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    background-color: white;
}
.card:hover .img2{
    transform: scale(1.3);
}
.active{
    transform: scale(1.01);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    background-color: white;
}
.active .img2{
    transform: scale(1.3);
}

.card p{
 z-index: 3;
}

.imgbox{
    height: 16rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px 15px  0px 0px;
    overflow: hidden;
}
.infoBox{
    padding: .9rem;
}
.para{
    width: 40rem;
}

@media (max-width:800px){
    .innerbox{
        grid-template-columns: 1fr 1fr;
    }
}
@media (max-width:713px){
    .innerbox{
        grid-template-columns: 1fr ;
    }
    .card{
        width: 30rem;
        height: 33rem;
    }
}
@media (max-width:620px){

    .card{
        width: 40rem;
        height: 30rem;
    }
    .para{
        width: 30rem;
    }
}
@media (max-width:446px){
    .innerbox{
        width: 100vw;
        place-content: center;
        place-items: center;
    }
    .card{
        width: 30rem;
        height: 30rem;
    }
}