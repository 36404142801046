/* General Container */
.welcome_container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    align-items: center;
    padding: 0 20px;
    overflow: hidden;
    color: #ffffff;
    padding: 0rem 7rem;
    padding-top: 5rem;
  }
  
  /* Text Section */
  .welcome_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
    gap: 20px;
  }
  
  /* h1 Styling */
  .welcome_text h1 {
    font-size: 3.5rem;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    margin: 0;
    padding: 0;
    line-height: 1.2;
    background: linear-gradient(90deg, #fca5a5, #a5d8fc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
    transition: transform 0.3s ease-in-out;
  }
  
  /* Subtext Styling */
  .subtext {
    font-size: 1.2rem;
    font-family: "Roboto", sans-serif;
    color: #e0e0e0;
    margin-top: -10px;
    line-height: 1.5;
  }
  .welcome_canvas {
    width: 100%;
}
body {
    margin: 0;
    padding: 0;
    font-family: "Helvetica Neue", sans-serif;
}
  
  /* Responsive h1 and Subtext Styling */
  @media screen and (max-width: 768px) {
    .welcome_container {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr;
      align-items: center;
      padding: 0 20px;
      overflow: hidden;
      color: #ffffff;
      padding: 0rem 0rem;
      padding-top: 5rem;
      width: 100%;
    }
    .welcome_text h1 {
      font-size: 2.5rem;
    }
    .subtext {
      font-size: 1rem;
    }
  }
  
  @media screen and (max-width: 480px) {
    .welcome_text h1 {
      font-size: 2rem;
    }
    .subtext {
      font-size: 0.9rem;
    }
  }
  
  /* Button Styling */
  .query_button {
    background-color: #4caf50!important;
    color: #ffffff!important;
    padding: 12px 24px!important;
    border-radius: 25px!important;
    font-size: 1rem!important;
    font-weight: 500!important;
    text-transform: none!important;
    transition: all 0.3s ease!important;
  }
  
  .query_button:hover {
    background-color: #45a049!important;
  }
  
  /* Canvas Section */
  .welcome_canvas {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70vh;
  }
  
  .welcome_canvas canvas {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
  }
  .img{
    height: 23rem;
  }
  
  /* Responsive Grid Layout */
  @media screen and (min-width: 768px) {

    .welcome_container {
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }
  
    .welcome_text {
      text-align: left;
      align-items: flex-start;
      padding: 40px;
    }
  
    .welcome_text h1 {
      text-align: left;
    }
  
    .subtext {
      text-align: left;
    }
  }
  
  @media (max-width:500px){
    .img{
      height: 16rem;
    }
    .welcome_canvas {
      width: 100%;
      height: 100%;
  }
  }