/* Main Section Styles */
.main {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 2rem 2rem;
  gap: 1rem;
}

/* Header Styling */
.main h1 {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
}

.para {
  text-align: center;
  font-size: 1.1rem;
  width: 80%;
  max-width: 700px;
  line-height: 1.8;
  margin-bottom: 2rem;
  color: rgba(255, 255, 255, 0.7);
}

/* Inner box that holds the industry items */
.innerbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
  width: 100%;
  max-width: 1000px;
}

/* Industry Item Styling */
.innerbox p {
  display: inline-block;
  padding: 0.8rem 2.5rem;
  background-color: #e9e9e9;
  font-weight: 500;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 10px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  font-size: 1rem;
}

/* Active Industry Style */
.active {
  background-color: #000000!important;
  color: white;
}

/* Inactive Industry Style */
.notActive {
  color: #333;
}

/* Hover Effect */
.innerbox p:hover {
  background-color: #1e1e1e;
  color: white;
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
  .main {
    padding: 2rem 1rem;
  }

  .para {
    font-size: 1rem;
    width: 90%;
  }

  .innerbox {
    gap: 1rem;
  }
}

@media (max-width: 768px) {
  .innerbox {
    flex-direction: column;
    align-items: center;
  }

  .innerbox p {
    font-size: 1.1rem;
    width: 80%;
    margin-bottom: 1rem;
  }
}

@media (max-width: 480px) {
  .main h1 {
    font-size: 2rem;
  }

  .para {
    font-size: 0.9rem;
  }

  .innerbox p {
    width: 90%;
    font-size: 1rem;
    padding: 1rem 1.5rem;
  }
}
