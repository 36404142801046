/* Global Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.footerContainer {
  background-color: #181818;
  color: #e0e0e0;
  padding: 3rem 1.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.footerWrapper {
  display: flex;
  align-items: center;
  gap: 6rem;
  width: 80%;
}

.logoSection {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.logo {
  height: 50px;
  margin-bottom: 1rem;
}

.aboutText {
  font-size: 0.95rem;
  line-height: 1.6;
  color: #b3b3b3;
  max-width: 300px;
}

.sectionTitle {
  font-size: 1.2rem;
  color: #ff9800;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-weight: 600;
}

.linksSection,
.contactSection {
  text-align: left;
}

.linkList,
.contactList {
  list-style: none;
  padding-left: 0;
}

.linkList li,
.contactList li {
  margin-bottom: 0.8rem;
  font-size: 0.9rem;
  color: #e0e0e0;
  transition: color 0.3s ease;
}

.linkList li a {
  color: #e0e0e0;
  text-decoration: none;
  transition: color 0.3s ease;
}

.linkList li a:hover {
  color: #ff9800;
}

.contactList li {
  font-size: 0.9rem;
  color: #b3b3b3;
}

.divider {
  width: 80%;
  margin: 0rem 0;
  height: 1px;
  background-color: #333;
}

.socialMedia {
  display: flex;
  justify-content: center;
  gap: 1.2rem;
  margin-top: 1.5rem;
}

.icon {
  font-size: 1.5rem;
  cursor: pointer;
  color: #e0e0e0;
  transition: color 0.3s, transform 0.3s;
}

.icon:hover {
  color: #ff9800;
  transform: scale(1.1);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .footerWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0rem;
    width: 100%;
  }

  .logoSection,
  .linksSection,
  .contactSection {
    text-align: center;
    align-items: center;
  }
}

@media (max-width: 480px) {


  .logo {
    height: 40px;
  }

  .socialMedia {
    flex-wrap: wrap;
    gap: 1rem;
  }
}
