.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  width: 100%;
}

.headerSection {
  text-align: center;
  margin: 2rem 0;
  padding: 2rem;
  margin-top: 5rem;
}

.heading {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.para {
  font-size: 1.2rem;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.5;
}

.container {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  padding: 2rem;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 2rem;
}

.formBox {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  animation: zoomIn 1s ease-in-out;
}

.rightBox {
  flex: 1;
  text-align: center;
  padding: 2rem;
}

.rightBox h3 {
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  font-weight: 600;
  color: #ffffff;
}

.logoGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.logoItem {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.logoItem:hover {
  transform: translateY(-10px);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 20px;
}

.img {
  max-width: 80%;
  max-height: 80%;
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 450px) {
  .heading {
    font-size: 1.8rem;
  }
  
  .para {
    font-size: 1rem;
  }

  .logoGrid {
    grid-template-columns: 1fr;
  }
}
