/* Navbar container styling */
.navBar {
  box-shadow: none !important;
  z-index: 1000;
  display: flex;
  align-items: center;
  background-color: #111 !important; /* Dark background */
  backdrop-filter: blur(2px) !important;
  width: 50% !important;
  border-radius: 30px !important;
  position: fixed;
  border: 1px solid rgb(105, 103, 103);
  margin-top: 0.5rem;
  opacity: 0.99 !important;

}

/* Toolbar layout */
.toolBar {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  background-color: #111 !important;
  width: 100%;
  border-radius: 30px !important;
}

/* Logo styling */
.logo img {
  cursor: pointer;
}

/* Menu styling */
.menu {
  display: flex;
  gap: 20px;
  align-items: center;
}

/* Drawer Menu */
.drawerMenu {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #111; /* Dark background for drawer */
  color: #fff;
  height: 100vh;
}

/* Menu item styling */
.menuItem {
  padding: 10px 0;
  cursor: pointer;
}

.menuText {
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  text-transform: capitalize;
}

.link {
  text-decoration: none;
  color: inherit;
}

.link:hover {
  color: #007bff; /* Blue on hover */
}

/* Quote Button */
.quoteButton {
  background-color: #ffffff !important;
  color: #111 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;
  padding: 10px 20px !important;
  border-radius: 5px !important;
  border: 2px solid transparent !important;
  transition: all 0.3s ease-in-out !important;
  cursor: pointer !important;
}

.quoteButton:hover {
  background-color: #111;
  color: #ffffff;
  border: 2px solid #ffffff;
  box-shadow: 0 0 10px #ffffff; /* Glow effect */
}

/* Responsive Styling */
@media (max-width: 800px) {
  .navBar {
    width: 100% !important;
  }

  .menu {
    display: none; /* Hide menu on smaller screens */
  }

  .toolBar {
    justify-content: space-between !important;
  }
}
