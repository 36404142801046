.modalBox {
  background-color: #1e1e2f!important; /* Dark background for modal */
  color: #ffffff!important;/* White text for the form */
  border-radius: 12px!important;
  padding: 16px!important;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3)!important;
}

.form {
  display: flex!important;
  flex-direction: column!important;
  gap: 16px!important;
}
