.formSectionWrapper {
    padding: 0 40px!important;
    color: #ffffff!important;
    height: max-content;
    width: 85%!important;
  }
  
  .gridContainer {
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
  }
  
  .quotesContainer {
    display: flex!important;
    flex-direction: column!important;
    justify-content: center!important;
    align-items: center!important;
    padding: 30px!important;
    border-radius: 10px!important;
  }
  
  .title {
    color: #C0C0C0 !important; /* Silver color */
    margin-bottom: 20px !important;
    font-weight: bold !important;
    text-shadow: 
      2px 2px 5px rgba(0, 0, 0, 0.7),  /* Darker shadow for depth */
      -2px -2px 5px rgba(0, 0, 0, 0.5); /* Lighter shadow for contrast */
    font-family: "Arial", sans-serif; /* Font family */
  }
  
  
  .quoteCard {
    background-color: transparent!important;
    color: #e0e0e0!important;
    margin-top: 20px!important;
    background-color: black!important;
    box-shadow: 0 0 20px rgba(0, 255, 204, 0.7)!important; /* Glowing shadow effect */
    border-radius: 15px!important;
  }
  
  .quoteText {
    font-style: italic!important;
    color: #b0bec5!important;
  }
  
  .authorText {
    text-align: right!important;
    margin-top: 10px!important;
    color: #757575!important;
  }
  @media (max-width:800px){
    .formSectionWrapper {
      width: 100%!important;
      padding: 0 5px!important;
    }
  }