.main{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0rem;
    flex-direction: column;
    gap: 2rem;
    text-align: center;
}
.main  p{
    width: 80%;
}
.container{
  width: 80%;
}

@media (max-width:500px){
  .container{
    width: 100%;
  }
}