.main{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 3rem 0rem;
}
.innerbox{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
    margin-top: 2rem;
}
.img{
    height: 3rem;
    width: 3rem;
}
.card{
    height: 20rem;
    width: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 5px 80px 5px 80px;
    text-align: left;
    padding: 1rem;
    gap: .5rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    /* background-color:  rgb(240, 240, 241); */
    transition: transform 0.3s ease-in-out;
}
.card:hover{
    transform: scale(1.01);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
@media (max-width:800px){
    .innerbox{
        grid-template-columns: 1fr 1fr;
    }
}
@media (max-width:713px){

    .card{
        height: 19rem;
        width: 19rem;
    }
}
@media (max-width:620px){

    .card{
        height: 25rem;
        width: 20rem;
    }
}
@media (max-width:446px){

    .card{
        height: 30rem;
        width: 15rem;
    }
}