/* Main Container */
.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding: 3rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
  background-color: transparent;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Title & Description */
.title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.description {
  font-size: 1.2rem;
  color: #ffffff;
  max-width: 800px;
  text-align: center;
  line-height: 1.6;
}

/* Button Container */
.btnsbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1.5rem;
}

.btn {
  padding: 0.75rem 1.5rem;
  background: linear-gradient(170deg, #0c0547 0%, #021558 47%, #1b29a3 100%);
  border: 1px solid #b7b2b2;
  color: white;
  cursor: pointer;
  font-size: 0.9rem;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.btn:hover {
  background-color: #1b29a3;
}

.active {
  background-color: #ff8c00;
  padding: 0.75rem 1.5rem;
  border: 1px solid #b7b2b2;
  color: white;
  cursor: pointer;
  font-size: 0.9rem;
  border-radius: 5px;
  transition: all 0.3s ease;
}

/* Tech Stack Container */
.container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
  width: 100%;
}

/* Tech Item Box */
.innerbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.innerbox:hover {
  transform: translateY(-8px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Tech Item Image & Name */
.img {
  height: 60px;
  width: 60px;
  margin-bottom: 0.75rem;
  filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.1));
}

.itemName {
  font-size: 0.9rem;
  color: #333;
  text-align: center;
}

/* Media Queries */
@media (max-width: 768px) {
  .description {
    max-width: 100%;
  }

  .btnsbox {
    flex-wrap: wrap;
  }

  .btn {
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
  }

  .container {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  }
}

@media (max-width: 480px) {
  .description {
    font-size: 1rem;
  }
  
  .btn {
    font-size: 0.75rem;
  }
}
