.main {
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 0rem;
    gap: 4rem;
    width: 100vw;
    background-color: #121212; /* Dark background for the main container */
    color: #e0e0e0; /* Light text color for better contrast */
}

.card_container {
    display: grid;
    grid-template-columns: auto auto auto;
    width: 80%;
    gap: 2rem;
}

.card {
    height: 20rem;
    border-radius: 15px;
    padding: 1rem;
    display: flex;
    filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.5)); /* Darker shadow for a more dramatic effect */
    flex-direction: column;
    overflow: hidden;
    text-align: left;
    background: rgb(32, 32, 32); /* Dark card background */
    background: linear-gradient(0deg, rgba(32, 32, 32, 1) 0%, rgba(48, 48, 48, 1) 100%, rgba(64, 64, 64, 0.1) 100%);
    color: #e0e0e0; /* Light text color */
}

.des {
    height: 15rem;
    overflow: hidden;
    color: #b0b0b0; /* Slightly dimmed description text color for readability */
}

@media (max-width: 620px) {
    .card_container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 90%;
        gap: 1rem;
        height: max-content;
    }
    .card {
        height: auto;
        padding: 1.5rem;
        background: rgb(32, 32, 32); /* Ensure card background remains dark */
    }
    .des {
        height: max-content;
        overflow: visible;
    }
    .main h2 {
        width: 100%;
        text-align: center;
        padding: 0 1rem;
        color: #e0e0e0; /* Light text color for heading */
    }
}

@media (max-width: 446px) {
    .card_container {
        grid-template-columns: 1fr;
        width: 100%;
        gap: 1rem;
    }
    .card {
        height: auto;
        padding: 1rem;
    }
    .main h2 {
        font-size: 1.5rem;
        padding: 0 1rem;
        color: #e0e0e0; /* Light text color for heading */
    }
}
