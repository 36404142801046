
.para {
  color: #b0b0b0; /* Light gray for paragraph text */
  width: 80%;
}

/* Main Container */
.main {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 3rem 0;
  text-align: center;
}

.main h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  width: 80%;
}

/* Inner box with cards */
.innerbox {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
  margin-top: 2rem;
  width: 80%;
}

.card {
  height: 25rem;
  width: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  text-align: left;
  padding: 1rem;
  gap: 0.5rem;
  background-color: #1f1f1f; /* Dark card background */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  transition: transform 0.3s ease-in-out;
  color: #ffffff; /* White text for card content */
}

.card:hover {
  transform: scale(1.05);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.card img {
  width: 60px;
  height: 60px;
  margin-bottom: 1rem;
  filter: brightness(0) invert(1);
  transition: filter 0.3s ease, transform 0.3s ease;
}


/* Card title styling */
.card h6 {
  font-size: 1.2rem;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 0.5rem;
}

/* Paragraph Styling */
.card p {
  font-size: 1rem;
  color: #b0b0b0;
}

/* Mobile Responsiveness */
@media (max-width: 800px) {
  .main {
    padding: 2rem;
  }

  .innerbox {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .card {
    height: auto;
    width: 90%;
    margin-bottom: 1.5rem;
  }

  .para {
    width: 90%;
    text-align: center;
  }

  .main h2 {
    font-size: 2rem;
  }

  .card h6 {
    font-size: 1rem;
  }
}

