.main {
  height: max-content;
  width: 100vw;
  padding: 4rem 0;
  text-align: center;
}

.navBar {
  width: 100%;
  max-width: 30rem;
  margin-top: 3rem;
  margin-left: auto;
  margin-right: auto;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: .5rem;
}

.optionList {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
}

.optionList li {
  cursor: pointer;
  padding: 1.5rem;
  position: relative;
  font-weight: 600;
  font-size: medium;
  color: #7a7676;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.optionList li:hover {
  background-color: rgb(234, 234, 232);
  color: black;
}

.optionContent,
.optionContent1,
.optionContent2,
.optionContent3,
.optionContent4,
.optionContent5 {
  position: absolute;
  top: 0;
  left: 110%;
  background-color: rgb(240, 240, 241);
  padding: .3rem 1rem;
  border-left: none;
  border-radius: .5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
  width: 20rem;
  height: 26rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  gap: .5rem;
  transition: left 0.3s ease;
}

.optionContent p {
  height: 14rem;
  overflow: hidden;
}

.optionContent h4 {
  width: 14rem;
  height: 3rem;
  overflow: hidden;
  font-size: 1.2rem;
}

.optionList li:hover .optionContent {
  left: 100%; /* Adjust based on hover effect */
}

@media (max-width: 768px) {
  .navBar {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  .optionList {
    flex-direction: column;
  }

  .optionContent,
  .optionContent1,
  .optionContent2,
  .optionContent3,
  .optionContent4,
  .optionContent5 {
    width: 100%;
    left: 100%;
  }
}
