.serviceListContainer {
    padding: 16px!important;
    background-color: #121212!important;
    color: #e0e0e0!important;
    max-height: 80vh!important;
    position: fixed!important;
    left: -25%;
    width: 70vw!important;
    border-radius: 20px!important;
    top: 110%;
    backdrop-filter: blur(10px) !important;
    opacity: 1!important;
  }
  
  .categoryList {
    background-color: #1e1e1e!important;
    border-radius: 8px!important;
    padding: 16px!important;
    backdrop-filter: blur(10px) !important;
  }
  
  .categoryButton {
    margin-bottom: 12px!important;
    font-size: 14px!important;
    font-weight: 500!important;
    text-transform: none!important;
    background-color: #1e1e1e!important;
    color: #e0e0e0!important;
    border: 1px solid #444!important;
    border-radius: 8px!important;
    transition: 0.3s!important;
    backdrop-filter: blur(10px) !important;
  }
  
  .categoryButton:hover {
    background-color: #333!important;
    color: #fff!important;
    backdrop-filter: blur(10px) !important;
  }
  
  .activeCategory {
    background-color: #333!important;
    color: #ffffff!important;
    font-weight: bold!important;
    backdrop-filter: blur(10px) !important;
  }
  
  .serviceDetails {
    background-color: #1e1e1e!important;
    border-radius: 8px!important;
    padding: 24px!important;
    backdrop-filter: blur(10px) !important;
  }
  
  .serviceCard {
    background-color: #2a2a2a!important;
    border-radius: 8px!important;
    padding: 12px!important;
    transition: transform 0.3s!important;
    backdrop-filter: blur(10px) !important;
  }
  
  .serviceCard:hover {
    transform: scale(1.05)!important;
  }
  
  .serviceItem {
    color: #e0e0e0!important;
    font-size: 14px!important;
    text-align: center!important;
  }
  
  .sectionTitle {
    color: #e0e0e0!important;
    font-weight: bold!important;
    margin-bottom: 12px!important;
  }
  
  .divider {
    background-color: #444!important;
    margin-bottom: 16px!important;
    backdrop-filter: blur(10px) !important;
  }
  
  .activeCategoryTitle {
    color: #ffffff!important;
    font-weight: bold!important;
    margin-bottom: 16px!important;
    text-align: center!important;
  }
  
  .placeholder {
    text-align: center!important;
    margin-top: 50px!important;
    color: #777!important;
  }
  