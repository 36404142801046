.main{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100vw;
}
.container{
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 2rem;
    width: 100vw;
    padding: 3rem 3rem;
    background-color: aliceblue;
}
.imgbox{
    height: max-content;
    overflow: hidden;
    display: grid;
    grid-template-columns:  1fr;
    padding: 1rem 0rem;
}
.imginnerbox{
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.imgcontainer{
    overflow: hidden;
    width: 90%;
    height: 30rem;
    border-radius: 10px;
}
.imgcontainer img{
    height: 100%;
    width: 100%;
    transition: all 0.3s ease-in-out;
}
.description p{
    font-size: 1.2rem;
}
.infobox{
    display: grid;
     grid-template-columns:  1fr;
    gap: 4rem;
    padding: 7rem 1rem;
    padding-bottom: 3rem;
}
.info{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding-bottom: 3rem;
    padding-right: 3rem;
}

.infobox span{
    color: rgb(112, 105, 105);
}
.para{
    width: 70rem;
    margin-top: -2rem;
}
.active{
    transform: scale(1.2);
}
.form{
   display: flex;
   flex-direction: column;
   gap: 1rem;
   text-align: center;
}
.rightBox{
    height: 70rem;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    padding: 2rem 0rem;
}
.rightBox::-webkit-scrollbar{
    display: none;
}
.card{
    width: 25rem;
    min-height: 20rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: rgb(237, 237, 237);
    border-radius: 10px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.card h5{
    overflow: hidden;
    height: 3rem;
}
.card:hover img{
    transform: scale(1.2);
}
.imgbox2{
    height:15rem ;
    overflow: hidden;
}
.imgbox2 img{
   height: 100%;
   width: 100%;
}

.para{
    height: 12rem;
    margin-top: -2rem;
    overflow: hidden;
    width: 40rem;
}
@media (max-width:800px){
 
    .container{
   display: flex;
   flex-direction: column;
    }
}