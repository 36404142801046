.main {
  background-color: #121212;
  color: white;
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.heading {
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1rem;
}

.subheading {
  font-size: 1.2rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  max-width: 800px;
}

.cardContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  width: 100%;
  max-width: 1200px;
  padding: 1rem;
}

.card {
  background-color: #1e1e1e;
  border-radius: 15px;
  padding: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: rgba(0, 0, 0, 0.6) 0px 5px 15px;
  opacity: 0.7;
  transform: scale(0.95);
}

.card.active {
  opacity: 1;
  transform: scale(1);
  box-shadow: rgba(0, 0, 0, 0.8) 0px 10px 30px;
}

.img {
  width: 60px;
  height: 60px;
  margin-bottom: 1rem;
  filter: brightness(0) invert(1); /* Makes the image white */
  transition: filter 0.3s ease, transform 0.3s ease;
}

/* Hover effect to add a subtle zoom effect */
.img:hover {
  transform: scale(1.1);
}

.cardHeading {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.cardText {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.7);
}

.no {
  font-size: 2rem;
  font-weight: 700;
  color: #ff6b6b;
}

@media (max-width: 1024px) {
  .cardContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .cardContainer {
    grid-template-columns: 1fr;
  }

  .heading {
    font-size: 2rem;
  }

  .subheading {
    font-size: 1rem;
  }

  .card {
    padding: 1.5rem;
  }
}
