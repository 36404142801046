/* General container styling */
.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #121212; /* Dark background for a modern look */
    color: #e0e0e0; /* Light text color for contrast */
    padding: 0rem 0;
    font-family: 'Roboto', sans-serif;
}

/* Container for the main section */
.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    max-width: 1200px;
    gap: 2rem;
    padding: 2rem 0;
    margin-top: 9rem;
}

/* Styling for text content */
.textContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    padding: 0 2rem;
}

.title {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    color: #ffffff; /* Bright title color */
}

.description {
    font-size: 1.2rem;
    line-height: 1.8;
    margin-bottom: 2rem;
    color: #b0b0b0; /* Light gray for readability */
}

/* Button styling */
.submitBtn {
    width: 12rem;
    padding: 12px;
    background: rgb(12, 5, 71);
    background: linear-gradient(170deg, rgba(12, 5, 71, 1) 0%, rgba(2, 21, 88, 1) 47%, rgba(27, 41, 163, 1) 100%);
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    transition: background-color 0.5s ease;
    align-self: flex-start;
}

.submitBtn:hover {
    background: orange;
    color: black;
}

/* Container for the image */
.imageContainer {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img {
    width: 100%;
    border-radius: 12px;
    animation: fadeIn 3s ease-in-out both;
}

/* Fade-in animation for the image */
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* Responsive adjustments */
@media (max-width: 1024px) {
    .container {
        flex-direction: column-reverse;
        gap: 2rem;
    }

    .textContainer, .imageContainer {
        width: 100%;
        padding: 0;
    }

    .title {
        text-align: center;
        font-size: 2rem;
    }

    .description {
        text-align: center;
        font-size: 1.1rem;
    }

    .submitBtn {
        align-self: center;
        width: 80%;
    }
}

@media (max-width: 799px) {
    .container {
        flex-direction: column-reverse;
        width: 100%;
        padding: 0 1rem;
    }

    .title {
        font-size: 1.8rem;
        margin-bottom: 1rem;
    }

    .description {
        font-size: 1rem;
    }

    .submitBtn {
        width: 100%;
        padding: 10px;
    }
}

@media (max-width: 599px) {
    .title {
        font-size: 1.5rem;
    }

    .description {
        font-size: 0.9rem;
    }

    .submitBtn {
        font-size: 14px;
        padding: 8px;
    }
}
