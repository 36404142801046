.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1rem;
    height: max-content;
  }
  
  .container {
    height: max-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 1rem;
    padding-top: 7rem;
  }
  
  .container div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 1rem;
    text-align: center;
    width: 100%;
    height: max-content;
  }
  
  .container h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .container h6 {
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
  }
  
  .container p {
    font-size: 1rem;
    margin-bottom: 1.5rem;
    line-height: 1.6;
  }
  
  .img_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
  }
  
  .img {
    max-width: 100%;
    height: auto;
    transition: all 0.5s ease-in-out;
  }
  
  .submitBtn {
    display: inline-block;
    padding: 12px 24px;
    background: rgb(12, 5, 71);
    background: linear-gradient(170deg, rgba(12, 5, 71, 1) 0%, rgba(2, 21, 88, 1) 47%, rgba(27, 41, 163, 1) 100%);
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1.25rem;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    transition: background-color 0.5s ease;
  }
  
  .submitBtn:hover {
    background: orange;
    color: black;
  }
  
  /* FadeIn Animation */
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  .img {
    animation: fadeIn 3s ease-in-out forwards;
  }
  
  /* Responsive Styles */
  
  /* Large screens (desktop and above) */
  @media (min-width: 1024px) {
    .container {
      flex-direction: row;
      justify-content: space-between;
    }
  
    .container div {
      width: 45%;
      padding-left: 6rem;
      text-align: left;
    }
  
    .img {
      max-width: 30rem;
    }
  
    .submitBtn {
      width: auto;
    }
  }
  
  /* Medium screens (tablets) */
  @media (max-width: 1023px) {
    .container {
      flex-direction: column;
    }
  
    .container div {
      width: 100%;
      padding-left: 3rem;
      text-align: center;
    }
  
    .img {
      width: 80%;
    }
  
    .submitBtn {
      width: 12rem;
    }
  }
  
  /* Small screens (mobile landscape) */
  @media (max-width: 799px) {
    .img {
      width: 25rem;
    }
  
    .submitBtn {
      width: 15rem;
    }
  }
  
  /* Extra small screens (mobile portrait) */
  @media (max-width: 500px) {
    .img {
      width: 100%;
      margin-left: 0;
      margin-top: 2rem;
    }
  
    .submitBtn {
      width: 10rem;
      font-size: 1rem;
    }
  
    .container p {
      width: 100%;
      font-size: 0.875rem;
    }
  
    .container h2 {
      font-size: 1.5rem;
    }
  
    .container h6 {
      font-size: 1rem;
    }
  
    .container div {
      padding-left: 1rem;
      align-items: center;
    }
  }
  
  /* Mobile optimization */
  @media (max-width: 500px) {
    .container p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  
    .container h2 {
      font-size: 1.25rem;
    }
  
    .container h6 {
      font-size: 0.875rem;
    }
  }
  

  
    .img_box{
        overflow: hidden;
    }