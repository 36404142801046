.main{
    display: flex;
    flex-direction: column;
    align-items: center;

}
.container{
    height: 34rem;
    width: 100vw;
    display: flex;
}
.container div{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 6rem;
    background-color: aliceblue;
}
.img_box{
    overflow: hidden;
}
.container h1{
    width: 30rem;
}
.container p{
    width: 38rem;
}
.img{
   width: 30rem;
}

.submitBtn {
    display: block;
    width: 10rem;
    padding: 10px;
    background: rgb(12,5,71);
    background: linear-gradient(170deg, rgba(12,5,71,1) 0%, rgba(2,21,88,1) 47%, rgba(27,41,163,1) 100%);
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    transition: background-color 0.5s ease;
  }
  
  .submitBtn:hover {
    background: orange;
    color: black;
  }
  .img {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    }
    @-webkit-keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
    }
    @keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
    } 
    @media (max-width:799px){
        .img{
            width: 25rem;
         }
         .submitBtn {
            width: 15rem;
         }
    }
    @media (max-width:786px){
        .img{
            width: 19rem;
         }
         .submitBtn {
            width: 15rem;
         }
         .container p{
            width: 20rem;
        }
        .container div{
            padding-left: 3rem;
         }
    }
    @media (max-width:500px){
        .img{
            width: 19rem;
         }
         .submitBtn {
            width: 10rem;
            font-size: 1rem;
         }
         .container div{
            padding-left: 1rem;
         }
    }
    @media (max-width:500px){
        
        .img{
            width: 19rem;
            position: absolute;
            margin-left: -2rem;
         }
         .submitBtn {
            width: 10rem;
            font-size: 1rem;
         }
         .container div{
            padding-left: 1rem;
         }
         .container p{
            width: 15rem;
            height: 15rem;
            overflow: hidden;
        }
        .container h1{
            width: 15rem;
        }
    }
    .closebtn{
        margin-top: 10px;
        cursor: pointer;
        color: #fff;
        background-color: #333;
        border: none;
        border-radius: 5px;
        padding: 10px 15px;
        position: fixed;
        top: 0;
        right: 0;
        z-index: 99;
    }
    .closebtn:hover{
        background-color: #555;
    }