/* Main Container */
.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1.5rem;
    padding: 3rem 1rem;
    color: #e0e0e0; /* Light text for contrast */
    overflow: hidden;
    position: relative;
    z-index: 1;
    background-color: #121212; /* Dark background for better contrast */
  }
  
  /* Header Styles */
  .main h1 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #00bcd4; /* Cyan color for emphasis */
    margin-bottom: 1rem;
    z-index: 9;
  }
  
  .main p {
    font-size: 1.2rem;
    width: 70%;
    line-height: 1.6;
    max-width: 800px;
    z-index: 9;
    color: #b0bec5; /* Subtle color for paragraph text */
  }
  
  /* Grid Container */
  .container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 1.5rem;
    width: 100%;
    max-width: 1200px;
    z-index: 9;
    margin-bottom: 2rem;
  }
  
  /* Counter Box */
  .counterBox {
    background-color: #1e1e1e; /* Dark background for counters */
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
  }
  
  .counterBox:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.5);
  }
  
  .counter {
    font-size: 2rem;
    color: #ff5722; /* Accent color for counters */
    margin-bottom: 0.5rem;
  }
  
  /* Counter Labels */
  .counterBox h6 {
    font-size: 1rem;
    color: #b0bec5;
    margin-top: 0.5rem;
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .main h1 {
      font-size: 2.2rem;
    }
  
    .main p {
      width: 80%;
      font-size: 1.1rem;
    }
  
    .container {
      grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    }
  }
  
  @media (max-width: 768px) {
    .main {
      padding: 2rem 1rem;
    }
  
    .main h1 {
      font-size: 2rem;
    }
  
    .main p {
      width: 90%;
    }
  
    .counter {
      font-size: 1.8rem;
    }
  
    .container {
      grid-template-columns:1fr
    }
  }
  
  @media (max-width: 480px) {
    .main {
      padding: 1.5rem 0.5rem;
    }
  
    .main h1 {
      font-size: 1.8rem;
    }
  
    .main p {
      font-size: 1rem;
      width: 100%;
    }
  
    .counter {
      font-size: 1.5rem;
    }
  
    .counterBox {
      padding: 1rem;
    }
  
    .container {
      gap: 1rem;
    }
    .container {
        grid-template-columns:1fr
      }
  }
  