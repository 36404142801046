.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 3rem 2rem;
  background-color: #121212; /* Dark background */
}

.title {
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1rem;
  color: #ffffff;
}

.description {
  font-size: 1.2rem;
  text-align: center;
  max-width: 800px;
  color: rgba(255, 255, 255, 0.7);
}

.innerbox {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  width: 100%;
  max-width: 1200px;
  margin-top: 2rem;
  padding: 0 1rem;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 22rem;
  padding: 1.5rem;
  border-radius: 15px;
  background: #1e1e1e;
  box-shadow: rgba(0, 0, 0, 0.6) 0px 10px 15px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  text-align: center;
  color: #ffffff;
  overflow: hidden;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: rgba(255, 255, 255, 0.1) 0px 19px 38px,
    rgba(255, 255, 255, 0.2) 0px 15px 12px;
}

.iconContainer {
  width: 4rem;
  height: 4rem;
  background: #292929;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.icon {
  color: #61dafb; /* Light blue color for icons */
  font-size: 2.5rem;
}

.cardTitle {
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.cardDescription {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.8);
}

@media (max-width: 1024px) {
  .innerbox {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .innerbox {
    grid-template-columns: 1fr;
  }

  .title {
    font-size: 2rem;
  }

  .description {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 1.8rem;
  }

  .description {
    font-size: 0.95rem;
  }

  .card {
    width: 100%;
    height: auto;
  }
}
