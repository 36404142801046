/* Blog.module.css */
.main{
   display: flex;
   flex-direction: column;
   height: max-content;
   overflow: hidden;
}
.container{
    display: grid;
    grid-template-columns: 2fr 1fr;
    width: 100%;
}
.leftBox{
    height: 40rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 2rem;
}
.rightBox{
    height: 40rem;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    padding: 2rem 0rem;
}
.rightBox::-webkit-scrollbar{
    display: none;
}
.card{
    width: 25rem;
    min-height: 20rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: rgb(237, 237, 237);
    border-radius: 10px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.card:hover img{
    transform: scale(1.2);
}
.container img{
    height: 100%;
    width: 100%;
    transition: all 0.3s ease-in-out;
}

.bigCard{
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 1.5rem;

}
.imgBox{
    height: 20rem;
    width: 50rem;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.imgbox2{
    height:15rem ;
    overflow: hidden;
}
.imgBox img{
    height: 100%;
    width: 100%;
    transition: all 0.3s ease-in-out;
}
.para{
    height: 12rem;
    margin-top: -2rem;
    overflow: hidden;
    width: 40rem;
  
}
.btn{
    margin-top: -2rem;
    width: 10rem;
    height: 2rem;
    border-radius: 15px;
    background-color: rgb(29, 29, 131);
    color: white;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
transition: all 0.3s ease-in-out;

}
.btn:hover{
    background-color: orange;
    color: black;

}
.active{
    transform: scale(1.2);
}
.searchInputbox{
    width: 25rem;
}
.parainfo{
    overflow: hidden;
    height: 5rem;
    padding: 0rem .5rem;
    
 
}
.bigCard {
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    }
    @-webkit-keyframes zoomIn {
    0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
    }
    50% {
    opacity: 1;
    }
    }
    @keyframes zoomIn {
    0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
    }
    50% {
    opacity: 1;
    }
    } 
    @media (max-width:400px){
        .imgBox{
            height: 20rem;
            width: 100%;
        }
        .bigCard{
            display: flex;
            flex-direction: column;
            text-align: left;
            gap: 1.5rem;
            width: 35rem;
            padding: 1rem;
            flex-wrap: nowrap;
        }
        .rightBox{
display: none;
        }
        .para{
            height: 12rem;
            margin-top: -2rem;
            overflow: hidden;
            width: 32rem;
          
        }
    }
    .closebtn{
        margin-top: 10px;
        cursor: pointer;
        color: #fff;
        background-color: #333;
        border: none;
        border-radius: 5px;
        padding: 10px 15px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 99;
    }
    .closebtn:hover{
        background-color: #555;
    }