.pageContainer {
    background-color: #121212!important;
    color: white!important;
    padding: 50px 0!important;
    text-align: center;
  }
  
  .industryCard {
    background-color: #1e1e1e!important;
    padding: 30px!important;
    border-radius: 10px!important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3)!important;
    display: flex!important;
    flex-direction: column!important;
    justify-content: center!important;
    align-items: center!important;
    transition: transform 0.3s ease, box-shadow 0.3s ease!important;
    text-align: center!important;
  }
  
  .industryCard:hover {
    transform: translateY(-10px)!important;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.5)!important;
  }
  
  .iconWrapper {
    background-color: #3e3e3e!important;
    border-radius: 50%!important;
    padding: 15px!important;
    margin-bottom: 20px!important;
    color: #fff!important;
    width: 60px!important;
    height: 60px!important;
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
  }
  
  .industryTitle {
    font-size: 1.2rem!important;
    font-weight: 600!important;
    color: #fff!important;
  }
  
  /* Mobile and Tablet Responsive Design */
  @media (max-width: 768px) {
    .industryCard {
      padding: 20px!important;
    }
  
    .industryTitle {
      font-size: 1rem!important;
    }
  }
  
  /* Larger Screens */
  @media (min-width: 1200px) {
    .industryCard {
      padding: 40px!important;
    }
  
    .industryTitle {
      font-size: 1.3rem!important;
    }
  }
  