/* Page Container */
.pageContainer {
    background-color: #121212!important;
    min-height: 100vh!important;
    padding: 40px 0!important;
    text-align: center!important;
  }
  
  /* Service Cards */
  .serviceCard {
    background-color: #1e1e1e!important;
    color: white!important;
    border-radius: 8px!important;
    padding: 20px!important;
    transition: transform 0.3s ease!important;
    height: 100%!important;
  }
  
  .serviceCard:hover {
    transform: translateY(-5px)!important;
  }
  
  .serviceTitle {
    font-size: 20px!important;
    font-weight: bold!important;
    color: #f5a623!important;
  }
  
  .serviceDescription {
    font-size: 14px!important;
    color: #e0e0e0!important;
    margin-top: 10px!important;
  }
  .subheading {
    font-size: 1.2rem;
    color: rgba(255, 255, 255, 0.6);
    max-width: 800px;
    text-align: center;
  }
  
  
  /* Responsive Typography and Layout */
  @media (max-width: 768px) {
    .serviceTitle {
      font-size: 18px!important;
    }
  
    .serviceDescription {
      font-size: 13px!important;
    }
  }
  