/* App.css */
.App {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  width: 100vw !important;
}

/* General body styles */
body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #121212!important; /* Dark background */
  overflow-y: scroll; /* Ensures smooth scrolling even when content is added dynamically */
  scroll-behavior: smooth; /* Smooth scroll behavior */
  -webkit-overflow-scrolling: touch; /* For smooth scrolling on touch devices */
  overflow-x: hidden;
  color: white !important;
}

/* For extremely smooth scrolling */
html {
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory; /* Snap to sections vertically if needed */
}

body::-webkit-scrollbar {
  width: 10px; /* Customize scrollbar width */
}

body::-webkit-scrollbar-track {
  background: #2c2c2c; /* Scrollbar track color */
}

body::-webkit-scrollbar-thumb {
  background-color: #555; /* Scrollbar thumb color */
  border-radius: 10px;
  border: 3px solid transparent; /* Creates padding around the thumb */
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #007aff; /* Color on hover */
}
