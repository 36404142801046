/* Main container style */
.main {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 1rem;
  gap: 2rem;
  text-align: center;
  background-color: #111; /* Dark background for contrast */
  color: #fff; /* White text for readability */
}

/* Heading styles */
.main .heading {
  font-size: 2.5rem;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Paragraph (description) styles */
.main .description {
  width: 50%;
  font-size: 1.125rem;
  line-height: 1.8;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 2rem;
}

/* Accordion container style */
.container {
  width: 70%;
  max-width: 1200px;
}

/* Responsive styles */
@media (max-width: 1000px) {
  .main .description {
    width: 70%;
  }

  .container {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .main .description {
    width: 85%;
  }

  .container {
    width: 90%;
  }
}

@media (max-width: 500px) {
  .main .heading {
    font-size: 2rem;
  }

  .main .description {
    width: 90%;
    font-size: 1rem;
  }

  .container {
    width: 100%;
  }
}
